import { render, staticRenderFns } from "./card-makanan.vue?vue&type=template&id=3a681f2f&"
import script from "./card-makanan.vue?vue&type=script&lang=js&"
export * from "./card-makanan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CCollapse: require('@chakra-ui/vue').CCollapse})
