<template>
  <c-flex flex-dir="column">
    <c-flex px="15px" mt="15px" align-items="center">
      <c-text
        font-weight="bold"
        font-size="16px"
        color="#111111"
        :is-truncated="true"
        flex="1"
      >
        {{ title }}
        <template v-if="menu.totalCalories !== 0">
          - {{ totalCalories(menu) }}kkal
        </template>
      </c-text>
      <c-flex v-if="showTime" align-items="center">
        <c-image
          :src="require('@/assets/icon-jam-grey.svg')"
          alt="icon waktu"
          h="18px"
          w="18px"
        />
        <c-text
          ml="6px"
          font-size="14px"
          text-align="right"
          color="#888888"
          :text-decoration="showTime ? '' : 'line-through 1px'"
        >
          {{ startTime }} - {{ endTime }}
        </c-text>
      </c-flex>
    </c-flex>
    <c-divider
      mx="15px"
      mb="15px"
      color="brand.900"
      border-bottom="1px solid"
      border-color="brand.900"
    />

    <card-makanan
      v-for="(meal, index) in meals"
      :key="index"
      :title="meal[0]"
      :meals="meal[1]"
      :portion="getPortionFor(meal[0])"
      v-chakra
      :mt="index > 0 ? '35px' : 0"
    />
  </c-flex>
</template>

<script>
import _ from "lodash"
import day from "dayjs"
import customFormat from "dayjs/plugin/customParseFormat"
import getMenuCategory from "@/utils/get-menu-category"
import CardMakanan from "./card-makanan.vue"

const dayjs = day.extend(customFormat)
export default {
  name: "CardDiet",
  components: {
    CardMakanan,
  },
  props: ["menu"],
  mounted() {
    window._l = _
  },
  computed: {
    title() {
      return getMenuCategory(this.menu.category)
    },
    startTime() {
      return dayjs(this.menu.timeStart, "HH:mm:ss").format("hh A")
    },
    endTime() {
      return dayjs(this.menu.timeEnd, "HH:mm:ss").format("hh A")
    },
    showTime() {
      return (
        this.menu.timeStart !== "00:00:00" && this.menu.timeEnd !== "00:00:00"
      )
    },
    meals() {
      return _(this.menu.meals) //
        .groupBy("foodGroup")
        .toPairs()
        .value()
    },
  },
  methods: {
    totalCalories(menu) {
      return menu.totalCalories / 100
    },
    getPortionFor(menu) {
      let portion = this.menu.portion?.find((it) => it.foodGroup === menu) ?? {
        dose: 0,
        doseUnit: "-",
      }

      return portion
    },
  },
}
</script>
