<template>
  <c-button
    variant="ghost"
    m="0"
    p="0"
    w="72px"
    h="auto"
    flex-dir="column"
    align-items="center"
    justify-content="flex-start"
    font-weight="initial"
    @click="isPopupOpen = true"
  >
    <c-image
      :src="photoURL"
      :alt="title"
      h="72px"
      w="72px"
      object-fit="cover"
      box-shadow="0px 0px 8px 5px rgba(0, 0, 0, 0.12)"
      border-radius="8px"
    />
    <c-text
      m="0"
      mt="5px"
      font-size="12px"
      text-align="center"
      color="#333333"
      max-w="100%"
      white-space="break-spaces"
      flex="1"
      v-chakra="{
        'word-wrap': 'anywhere'
      }"
    >
      {{ title }}
    </c-text>
    <c-text mt="10px" font-size="12px" text-align="center" color="#888888">
      {{ dose }} {{ doseUnit }}
    </c-text>
  </c-button>
</template>

<script>
export default {
  name: 'CardGlossaryItem',
  props: ['title', 'photoUrl', 'dose', 'doseUnit'],
  computed: {
    photoURL() {
      return this.photoUrl ?? 'https://via.placeholder.com/72';
    },
  },
};
</script>
